import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { loadCldr } from '@syncfusion/ej2-base';

import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import * as gregorian from 'cldr-data/main/nl/ca-gregorian.json';
import * as numbers from 'cldr-data/main/nl/numbers.json';
import * as timeZoneNames from 'cldr-data/main/nl/timeZoneNames.json';

loadCldr(
  numberingSystems['default'],
  gregorian['default'],
  numbers['default'],
  timeZoneNames['default']
);

@Component({
  selector: 'cp-root',
  templateUrl: './customer-portal.component.html',
})
export class CustomerPortalComponent {
  constructor(translate: TranslateService) {
    translate.setDefaultLang('en');
  }
}
