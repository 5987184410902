import { Component, OnInit, inject } from '@angular/core';
import { profileOptions } from '../profile-options';
import { IdentityServiceBase } from '@seahorse/auth';
import { SeahorseDropDownItem } from '@seahorse/ui';
import { PreferencesDataService } from '../../shared/services/preferences-data.service';

@Component({
  selector: 'cp-app-header',
  template: `
    <sh-header [appHeader]="true" [appName]="appName">
      <sh-avatar position="right">
        <sh-drop-down
          [options]="profileOptions"
          [showCarret]="false"
          (selectItem)="onItemSelect($event)"
          >{{ initials | uppercase }}</sh-drop-down
        >
      </sh-avatar>
    </sh-header>
  `,
})
export class AppHeaderComponent implements OnInit {
  profileOptions = profileOptions;
  appName = '';
  private _identityService = inject(IdentityServiceBase);
  private _preferencesService = inject(PreferencesDataService);

  initials = '';

  ngOnInit(): void {
    this.getInitials();

    this._preferencesService.displayName$.subscribe(
      (displayName) => (this.appName = displayName)
    );
  }

  getInitials() {
    if (this._identityService.identity) {
      const { givenName, surname } = this._identityService.identity;

      if (givenName && givenName.length > 0) {
        this.initials += givenName[0];
      }

      if (surname && surname.length > 0) {
        this.initials += surname[0];
      }
    }
  }

  onItemSelect(item: SeahorseDropDownItem) {
    if (item.id === 'logout') {
      this.logout();
    }
  }

  logout() {
    this._identityService.clearIdentity();
  }
}
