<ejs-sidebar
  class="full-height-sidebar"
  type="Auto"
  [enableDock]="true"
  [target]="host"
  [isOpen]="isOpen"
  (created)="sidebarCreated()"
>
  <ejs-menu
    class="scrollable-menu"
    orientation="Vertical"
    (select)="select($event)"
    (created)="menuCreated()"
    (beforeItemRender)="onBeforeItemRender($event)"
  >
    <e-menu-items>
      <!-- top -->
      <ng-container *ngFor="let item of menu">
        <e-menu-item
          *ngIf="!item.placement || item.placement === 'top'"
          [id]="item.label"
          [text]="isOpen ? item.label : ''"
          [iconCss]="'fa fa-light ' + item.icon"
          [url]="item.routerLink"
        ></e-menu-item>
      </ng-container>

      <e-menu-item id="separator"></e-menu-item>

      <!-- bottom -->
      <ng-container *ngFor="let item of menu">
        <e-menu-item
          *ngIf="item.placement === 'bottom'"
          [id]="item.label"
          [text]="isOpen ? item.label : ''"
          [iconCss]="'fa fa-light ' + item.icon"
        ></e-menu-item>
      </ng-container>

      <e-menu-item
        id="collapse-button"
        [iconCss]="
          isOpen
            ? 'e-icons e-chevron-left-double'
            : 'e-icons e-chevron-right-double'
        "
      ></e-menu-item>
    </e-menu-items>
  </ejs-menu>
</ejs-sidebar>
