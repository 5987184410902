import { inject } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { SeahorseRoute } from '@seahorse/common';
import { SeahorseBreadcrumb } from './breadcrumb.model';

export function getRouteBreadcrumbs() {
  const route = inject(ActivatedRoute);

  const breadcrumbs: SeahorseBreadcrumb[] = [];
  let currentRoute: ActivatedRouteSnapshot | null = route.snapshot;

  while (currentRoute) {
    const config = currentRoute.routeConfig as SeahorseRoute | null;

    if (config?.crumb) {
      const crumb: SeahorseBreadcrumb = {
        text: config.crumb,
        routerLink: config.path,
      };

      breadcrumbs.push(crumb);
    }

    currentRoute = currentRoute.parent;
  }

  return breadcrumbs.reverse();
}
