import { SeahorseDropDownItem } from '@seahorse/ui';

export const profileOptions: SeahorseDropDownItem[] = [
  {
    id: 'profile',
    text: 'Profile',
    iconCss: 'e-icons e-user',
    routerLink: 'profile',
  },
  {
    id: 'settings',
    text: 'Settings',
    iconCss: 'e-icons e-settings',
    routerLink: 'profile/preferences',
  },
  {
    id: 'logout',
    text: 'Logout',
    iconCss: 'e-icons e-export',
  },
];
