import { Injectable } from '@angular/core';
import { ProxyService } from '@seahorse/domain';
import { SeahorseMenuItem } from '@seahorse/layout';

@Injectable({
  providedIn: 'root',
})
export class MenuBuilderService {
  constructor(private _proxyService: ProxyService) {}

  buildMenuItems(): SeahorseMenuItem[] {
    const modules = this._proxyService.uiModules;

    const menuItems: SeahorseMenuItem[] = modules.map((module) => {
      return {
        label: module.displayName,
        icon: module.displayIcon,
        routerLink:
          '/module/' +
          module.systemCode +
          '/' +
          (module.entryRoute ? module.entryRoute : 'overview'),
      };
    });

    return menuItems;
  }
}
