import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AppBarModule,
  MenuModule,
  SidebarModule,
} from '@syncfusion/ej2-angular-navigations';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { SeahorseCommonModule } from '@seahorse/common';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AppLayoutComponent } from './layouts/app-layout.component';
import { PageLayoutComponent } from './layouts/page-layout.component';
import { SeahorseUiModule } from '@seahorse/ui';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    // Syncfusion
    AppBarModule,
    ButtonModule,
    SidebarModule,
    MenuModule,
    // Seahorse
    SeahorseCommonModule,
    SeahorseUiModule,
  ],
  declarations: [
    HeaderComponent,
    SidebarComponent,
    AppLayoutComponent,
    PageLayoutComponent,
  ],
  exports: [
    AppLayoutComponent,
    SidebarComponent,
    HeaderComponent,
    PageLayoutComponent,
  ],
})
export class SeahorseLayoutModule {}
