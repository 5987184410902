import { Component } from '@angular/core';
import { getRouteBreadcrumbs } from '@seahorse/ui';

@Component({
  selector: 'cp-page-layout',
  template: `
    <sh-page-layout>
      <sh-header>
        <sh-breadcrumb
          *ngIf="breadcrumbs"
          [crumbs]="breadcrumbs"
        ></sh-breadcrumb>
      </sh-header>
      <ng-content></ng-content>
    </sh-page-layout>
  `,
  styles: [
    `
      :host {
        --sh-header-height: 2rem;
        --sh-header-padding: 1.2rem 0.5rem;
        --sh-header-background-color: #ffffff;
      }
    `,
  ],
})
export class PageLayoutComponent {
  breadcrumbs = getRouteBreadcrumbs();
}
