import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CompanyReferenceDataService } from '../../services/company-reference-data.service';
import { switchMap } from 'rxjs/operators';
import { IdentityServiceBase } from '@seahorse/auth';

@Component({
  selector: 'cp-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class InvitationComponent implements OnInit, OnDestroy {
  private _route = inject(ActivatedRoute);
  private _router = inject(Router);
  private _companyReferenceService = inject(CompanyReferenceDataService);
  private _identityService = inject(IdentityServiceBase);

  token: string;
  accepted = false;

  private companyId: number;
  private _subscription = new Subscription();

  ngOnInit() {
    if (!this._identityService.isAuthenticated) {
      const currentUrl = this._router.url;
      this._router.navigate(['/login'], {
        queryParams: { returnUrl: currentUrl },
      });
      return;
    }

    this._subscription.add(
      this._route.queryParamMap
        .pipe(
          switchMap((params) => {
            this.token = params.get('key') || '';
            return this._companyReferenceService.getCompanyId(this.token);
          }),
          switchMap((res) => {
            if (!res.hasResult) {
              return [];
            }

            this.companyId = res.result;

            if (this.companyId) {
              return this._companyReferenceService.acceptInvitation();
            }

            return [];
          })
        )
        .subscribe((response) => {
          if (response) {
            this.accepted = true;
            this._router.navigate(['/dashboard'], {
              queryParams: { isCpInviteAccepted: true },
            });
          }
        })
    );
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}
