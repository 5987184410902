<ejs-appbar colorMode="Light">
  <div *ngIf="appHeader" class="app-logo"></div>
  <div *ngIf="appName">{{ appName }}</div>

  <ng-content> </ng-content>

  <div class="e-appbar-spacer"></div>

  <ng-content select="[position=center]"></ng-content>

  <div class="e-appbar-spacer"></div>

  <ng-content select="[position=right]"></ng-content>
</ejs-appbar>
