import { inject, Injectable } from '@angular/core';
import { DataContextService } from '@seahorse/common';
import { UiModule } from '@seahorse/domain';

@Injectable({
  providedIn: 'root',
})
export class UiModulesDataService {
  private _dataContext = inject(DataContextService);

  getAll() {
    return this._dataContext.get<UiModule[]>(`customdata/uimodule`);
  }
}
