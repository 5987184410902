import { DropDownListOption } from '@seahorse/forms';

export const languageOptions: DropDownListOption[] = [
  {
    value: 'nl',
    label: 'Nederlands',
    icon: 'flag-icon flag-icon-nl',
  },
  {
    value: 'en',
    label: 'English',
    icon: 'flag-icon flag-icon-gb',
  },
];
