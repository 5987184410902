import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'sh-app-layout',
  template: `
    <div class="master-layout">
      <ng-content select="[app-bar], sh-header"></ng-content>
      <div class="main-content">
        <sh-sidebar class="sidebar"></sh-sidebar>
        <div class="content">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .master-layout {
        display: flex;
        flex-direction: column;
        height: 100vh;
      }
      .main-content {
        display: flex;
        flex: 1;
        overflow: hidden;
      }
      .sidebar {
        position: sticky;
        top: var(--sh-header-height);
      }
      .content {
        width: 100%;
        overflow: auto;
        background-color: #f2f2f2;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppLayoutComponent {}
