import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { PreloaderService } from '../../shared/services/preloader.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'cp-master-layout',
  template: `
    <sh-app-layout
      *ngIf="status.isLoading === false && status.preloaded === true"
    >
      <cp-app-header app-bar></cp-app-header>
      <router-outlet></router-outlet>
    </sh-app-layout>
  `,
})
export class AppLayoutComponent implements OnInit, OnDestroy {
  status = {
    isLoading: true,
    preloaded: false,
  };

  private _subscriptions = new Subscription();

  private _preloaderService = inject(PreloaderService);
  ngOnInit() {
    this._subscriptions.add(
      this._preloaderService.preload().subscribe(() => {
        this.status.isLoading = false;
        this.status.preloaded = true;
      })
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
}
