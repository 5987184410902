<div class="simple-activation-wrapper">
  <ng-template #loginLogo>
    <div class="logo-container">
      <img
        src="../../../assets/shipm8-logo.svg"
        alt="{{ 'portal' | translate }}"
        class="logo"
      />
    </div>
  </ng-template>

  <section>
    <ng-container *ngTemplateOutlet="loginLogo"></ng-container>
    <p class="text-center">
      <br />
      {{ 'account.invitation.processing' | translate }}
    </p>
  </section>
</div>
