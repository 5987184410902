import {
  NauticalShipBoxComponent,
  UiModuleCustomComponent,
  UiModuleCustomComponents,
} from '@seahorse/temp';

export const uiModuleCustomComponents: UiModuleCustomComponents = [
  <UiModuleCustomComponent<NauticalShipBoxComponent>>{
    key: '$nautical_ship',
    type: 'linkedObjectCard',
    component: NauticalShipBoxComponent,
    data: (title, id, displayFields) => ({
      title,
      shipId: id,
      displayFields,
    }),
  },
];
