import { Injectable, inject } from '@angular/core';
import { ProxyService } from '@seahorse/domain';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PreloaderService {
  private _proxyService = inject(ProxyService);

  preload(): Observable<boolean> {
    return this._proxyService.getData();
  }
}
