import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SeahorseLayoutModule } from '@seahorse/layout';
import { AppLayoutComponent } from './components/app-layout.component';
import { RouterModule } from '@angular/router';
import { SeahorseUiModule } from '@seahorse/ui';
import { AppHeaderComponent } from './components/app-header.component';
import { PageLayoutComponent } from './components/page-layout.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SeahorseLayoutModule,
        SeahorseUiModule
    ],
    declarations: [
        AppLayoutComponent,
        AppHeaderComponent,
        PageLayoutComponent
    ],
    exports: [
        AppLayoutComponent,
        AppHeaderComponent,
        PageLayoutComponent
    ],
})
export class LayoutModule {}