import { inject, Injectable } from '@angular/core';
import { DataContextService } from '@seahorse/common';
import { FieldTypeDefinitionModel } from '@seahorse/domain';

@Injectable({
  providedIn: 'root',
})
export class FieldTypeDefinitionsDataService {
  private _dataContext = inject(DataContextService);

  getAll() {
    return this._dataContext.get<FieldTypeDefinitionModel[]>(
      `customdata/fieldtypedefinitions`
    );
  }
}
