import { Injectable } from "@angular/core";

@Injectable()
export class CpEnvService {
  apiHost = '';
  sfKey = '';
  theme = '';
  enableDebug = true;
  latestVersion = '';
}
