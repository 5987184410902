<sh-page-layout>
  <sh-header>
    <sh-drop-down-list
      position="right"
      [formControl]="language"
      [options]="languages"
    >
    </sh-drop-down-list>
  </sh-header>

  <div class="login row">
    <img src="../../../assets/shipm8-logo.svg" alt="{{'portal' | translate}}" />

    <div *ngIf="currentForm === formType.Login" class="form-wrapper">
      <form [formGroup]="form">
        <sh-text-input
          formControlName="username"
          placeholder="{{'login.username' | translate}}"
        >
        </sh-text-input>
        <sh-password
          formControlName="password"
          placeholder="{{'login.password' | translate}}"
        >
        </sh-password>
        <sh-checkbox
          formControlName="rememberMe"
          placeholder="{{'login.keepMe' | translate}}"
        ></sh-checkbox>
        <sh-button (click)="login()">{{'login.text' | translate}}</sh-button>
      </form>
      <sh-message
        *ngIf="isUnauthorized"
        [content]="'Username or password is incorrect'"
        [severity]="'Error'"
      ></sh-message>
    </div>

    <div *ngIf="currentForm === formType.VerifyMFA">
      <label class="control-label text-center mb-3" for="VerificationCode">
        {{ 'user.mfa.verificationCode.login' | translate }}
      </label>
      <sh-text-input [formControl]="verificationCode"></sh-text-input>
      <sh-message
        *ngIf="showVerificationError"
        [content]="'user.mfa.invalidVerificationCode' | translate"
        [severity]="'Error'"
      ></sh-message>
      <div class="d-flex justify-content-between mt-2">
        <sh-button
          link
          (click)="currentForm = formType.Login; showVerificationError = false"
        >
          {{ 'user.mfa.backToLogin' | translate }}
        </sh-button>
        <sh-button (click)="sendVerificationCode(verificationCode.value)">
          {{ 'shared.terms.verify' | translate }}
        </sh-button>
      </div>
    </div>

    <div *ngIf="currentForm === formType.SetupMFA">
      <temp-mfa-setup #mfaSetup [setupCode]="mfaSetupCode"></temp-mfa-setup>
      <sh-message
        *ngIf="showVerificationError"
        [content]="'user.mfa.invalidVerificationCode' | translate"
        [severity]="'Error'"
      ></sh-message>
      <div class="d-flex justify-content-between mt-2">
        <sh-button
          link
          (click)="currentForm = formType.Login; showVerificationError = false"
        >
          {{ 'user.mfa.backToLogin' | translate }}
        </sh-button>
        <sh-button (click)="completeMfa()">
          {{ 'shared.terms.confirm' | translate }}
        </sh-button>
      </div>
    </div>

    <sh-loading-indicator
      class="text-center"
      *ngIf="isLoading"
    ></sh-loading-indicator>
  </div>
</sh-page-layout>
