import { Injectable } from '@angular/core';
import { DataContextService, ResultWrapper } from '@seahorse/common';
import { CompanyReferenceModel } from '@seahorse/domain';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompanyReferenceDataService {
  constructor(private dataContext: DataContextService) {}

  getCompanyId(token: string): Observable<ResultWrapper<number>> {
    return this.dataContext.get<number>(
      `companyreference/company?key=${token}`
    );
  }

  acceptInvitation(): Observable<ResultWrapper<CompanyReferenceModel>> {
    return this.dataContext.put<CompanyReferenceModel>(
      `companyreference/set-accepted-status`,
      null
    );
  }
}
