import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, inject, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { authRequestInterceptor, AUTH_ROUTES } from '@seahorse/auth';
import {
  BUILD_NO,
  EnvService,
  envServiceProvider,
  NotificationService,
  SeahorseCommonModule,
  SeahorseTranslateModule,
} from '@seahorse/common';
import { ProxyService, UsersDataService } from '@seahorse/domain';
import { SeahorseFormsModule } from '@seahorse/forms';
import {
  SeahorseLayoutModule,
  SeahorseMenuItem,
  SIDEBAR_MENU,
} from '@seahorse/layout';
import {
  BaseFileListCardService,
  UI_MODULE_CUSTOM_COMPONENTS,
} from '@seahorse/temp';
import { SeahorseUiModule } from '@seahorse/ui';
import { ToastModule } from '@syncfusion/ej2-angular-notifications';
import { registerLicense } from '@syncfusion/ej2-base';
import { SEAHORSE_PORTAL_BUILD_NO } from '../build';
import { AuthModule } from './auth/auth.module';
import { CpUserService } from './auth/services/user.service';
import { CustomerPortalComponent } from './customer-portal.component';
import { appRoutes } from './customer-portal.routes';
import { uiModuleCustomComponents } from './features/generic-ui/models/pages-overrides';
import { LayoutModule } from './layout/layout.module';
import { AppInitializerService } from './shared/services/app-initializer.service';
import { CpEnvService } from './shared/services/env.service';
import { MenuBuilderService } from './shared/services/menu-builder.service';
import { CpNotificationService } from './shared/services/notification.service';
import { CpProxyService } from './shared/services/proxy.service';
import { FileListCardService } from './features/files/services/file-list-card.service';
import { AccountModule } from './account/account.module';

const sidebar: SeahorseMenuItem[] = [
  {
    label: 'Overview',
    icon: 'fa-house',
    routerLink: '/dashboard',
  },
  {
    label: 'Users',
    icon: 'fa-users',
    routerLink: '/users',
  },
];

@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes),
    ReactiveFormsModule,
    LayoutModule,
    AuthModule,
    AccountModule,
    // seahorse
    SeahorseCommonModule,
    SeahorseFormsModule,
    SeahorseTranslateModule.forRoot(),
    SeahorseLayoutModule,
    SeahorseUiModule,
    ToastModule,
  ],
  declarations: [CustomerPortalComponent],
  providers: [
    envServiceProvider(CpEnvService),
    provideHttpClient(withInterceptors([authRequestInterceptor])),
    { provide: EnvService, useExisting: CpEnvService },
    {
      provide: SIDEBAR_MENU,
      useFactory: (menuBuilderService: MenuBuilderService) => [
        ...sidebar,
        ...menuBuilderService.buildMenuItems(),
      ],
      deps: [MenuBuilderService],
    },
    { provide: BUILD_NO, useValue: SEAHORSE_PORTAL_BUILD_NO },
    { provide: ProxyService, useFactory: () => inject(CpProxyService) },
    { provide: UsersDataService, useFactory: () => inject(CpUserService) },
    {
      provide: NotificationService,
      useFactory: () => inject(CpNotificationService),
    },
    {
      provide: UI_MODULE_CUSTOM_COMPONENTS,
      useValue: uiModuleCustomComponents,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => new AppInitializerService().init(),
      multi: true,
    },
    {
      provide: BaseFileListCardService,
      useFactory: () => inject(FileListCardService),
    },
    {
      provide: AUTH_ROUTES,
      useValue: {
        login: 'login',
        unauthorized: 'not-authorized',
      },
    },
  ],
  bootstrap: [CustomerPortalComponent],
})
export class CustomerAppModule {
  constructor(_envService: CpEnvService) {
    // Registering Syncfusion license key
    registerLicense(_envService.sfKey);
  }
}
