import { HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { IdentityServiceBase } from '@seahorse/auth';
import { DataContextService, ResultWrapper } from '@seahorse/common';
import {
  AdminUserModel,
  UserInfoModel,
  UserModel,
  UsersDataService,
} from '@seahorse/domain';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CpUserService extends UsersDataService {
  private _router = inject(Router);
  private _identityService = inject(IdentityServiceBase);
  private _dataContext = inject(DataContextService);

  getProfile() {
    return this._dataContext.get<UserInfoModel>(`users/me`);
  }

  setUserIdentity(): Observable<string[]> {
    return this.getProfile().pipe(
      map((userResult) => {
        if (userResult.hasResult) {
          this._identityService.identity = userResult.result.user;
          this._identityService.setPermissions(userResult.result.permissions);

          return userResult.result.permissions;
        } else {
          localStorage.clear();
          this._router.navigate(['/login']);
        }
      })
    );
  }

  add(user: Partial<AdminUserModel>) {
    return this._dataContext.post<UserModel>(`user/management`, user);
  }

  update(user: UserModel) {
    return this._dataContext.put<UserModel>(`user/management`, user);
  }

  getByOrganisation(): Observable<ResultWrapper<UserModel[]>> {
    return this._dataContext.get<UserModel[]>(`user/management/byorganisation`);
  }

  getByOrganisationPaged(pageIndex: number, pageSize: number) {
    const params = new HttpParams()
      .set('pageIndex', pageIndex.toString())
      .set('pageSize', pageSize.toString());

    return this._dataContext.get<UserModel[]>(
      `user/management/byorganisation?${params.toString()}`
    );
  }
}
