import { inject } from '@angular/core';
import { PreferencesDataService } from './preferences-data.service';

export class AppInitializerService {
  private _preferencesInfoData = inject(PreferencesDataService);

  init() {
    return () => this._preferencesInfoData.loadPreferences();
  }
}
