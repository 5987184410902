import { inject, Injectable } from '@angular/core';
import { DataContextService } from '@seahorse/common';
import { ObjectDefinitionModel } from '@seahorse/domain';

@Injectable({
  providedIn: 'root',
})
export class ObjectDefinitionsDataService {
  private _dataContext = inject(DataContextService);

  getAll(pageIndex?: number, pageSize?: number, includeSystem = false) {
    const args = [];

    if (pageIndex !== undefined) {
      args.push(`pindex=${pageIndex}`);
    }

    if (pageSize !== undefined) {
      args.push(`psize=${pageSize}`);
    }

    if (includeSystem) {
      args.push(`includeSystem=${includeSystem}`);
    }

    const url = `customdata/objectdefinitions?${args.join('&')}`;
    return this._dataContext.get<ObjectDefinitionModel[]>(url);
  }
}
