import { Injectable } from '@angular/core';
import { ErrorMessage, NotificationService } from '@seahorse/common';
import { Toast, ToastUtility } from '@syncfusion/ej2-angular-notifications';

@Injectable({
  providedIn: 'root',
})
export class CpNotificationService extends NotificationService {
  toast: Toast;

  showInfo(message?: string, title?: string, timeout?: number) {
    this.showMessage(title, message, 'Information', timeout);
  }

  showSuccess(message?: string, title?: string, timeout?: number) {
    this.showMessage(title, message, 'Success', timeout);
  }

  showWarning(message?: string, title?: string, timeout?: number) {
    this.showMessage(title, message, 'Warning', timeout);
  }

  showError(
    message?: string | ErrorMessage | string[] | ErrorMessage[],
    title?: string,
    timeout?: number
  ) {
    let messageBody: string = null;
    if (!message && !title) {
      messageBody = 'shared.errorMessages.anErrorHasOccurred';
    } else if (Array.isArray(message)) {
      const errorMessages: string[] = [];
      message.forEach((resultMessage) => {
        if (typeof resultMessage === 'string' && resultMessage !== null) {
          errorMessages.push(this.translateService.instant(resultMessage));
        } else if (resultMessage instanceof ErrorMessage) {
          if (resultMessage.message) {
            errorMessages.push(
              this.translateService.instant(resultMessage.message)
            );
          }
        }
      });
      messageBody = errorMessages.join('\n');
    } else if (message instanceof ErrorMessage) {
      messageBody = message.message;
    } else {
      messageBody = message;
    }

    this.showMessage(title, messageBody, 'Error', timeout);
  }

  private showMessage(title: string, content: string, type: string, timeout?: number) {
    this.toast = ToastUtility.show(
      {
        title: title ? this.translateService.instant(title) : null,
        content: content ? this.translateService.instant(content) : null,
        position: { X: 'Right', Y: 'Bottom' },
        showCloseButton: true,
      },
      type,
      timeout
    );
  }

  clear(): void {
    this.toast?.hide('All');
  }
}
