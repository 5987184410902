import { inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DataContextService } from '@seahorse/common';
import { PreferencesModel } from '@seahorse/domain';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PreferencesDataService {
  displayName$ = new BehaviorSubject<string>('');
  private _dataContext = inject(DataContextService);
  private _titleService = inject(Title);

  loadPreferences() {
    this._dataContext
      .get<PreferencesModel>(`personalisation/url`)
      .subscribe((data) => {
        this.displayName$.next(data.result?.displayName ?? '');
        this._titleService.setTitle(this.displayName$.value);
      });
  }
}
