import { inject, Injectable } from '@angular/core';
import { ProxyService } from '@seahorse/domain';
import { forkJoin, Observable } from 'rxjs';
import { FieldTypeDefinitionsDataService } from '../../features/custom-data/services/field-type-definitions-data.service';
import { ObjectDefinitionsDataService } from '../../features/custom-data/services/object-definitions-data.service';
import { UiModulesDataService } from '../../features/custom-modules/services/ui-modules-data.service';

@Injectable({
  providedIn: 'root',
})
export class CpProxyService extends ProxyService {
  private _fieldTypeDefinitionsDataService = inject(
    FieldTypeDefinitionsDataService
  );
  private _objectDefinitionsDataService = inject(ObjectDefinitionsDataService);
  private _uiModulesDataService = inject(UiModulesDataService);

  getData(): Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      const filedTypes = this._fieldTypeDefinitionsDataService.getAll();
      const customObjects = this._objectDefinitionsDataService.getAll(
        -1,
        -1,
        true
      );
      const uiModules = this._uiModulesDataService.getAll();

      forkJoin([filedTypes, customObjects, uiModules]).subscribe(
        (results) => {
          this.fieldTypeDefinitions = results[0].result;
          
          this.objectDefinitions = results[1].result;
          this.resetObjectKeyMappings();

          this.uiModules = results[2].result;

          subscriber.next(true);
          subscriber.complete();
        },
        () => {
          subscriber.next(false);
          subscriber.complete();
        }
      );
    });
  }
}
