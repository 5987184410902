import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  ViewChild,
} from '@angular/core';
import {
  MenuComponent,
  MenuEventArgs,
  SidebarComponent as SFSidebarComponent,
} from '@syncfusion/ej2-angular-navigations';
import { SIDEBAR_LOCAL_STORAGE, SIDEBAR_MENU } from './sidebar.model';
import { Router } from '@angular/router';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'sh-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {
  @ViewChild(SFSidebarComponent) sidebarRef?: SFSidebarComponent;
  @ViewChild(MenuComponent) menuRef?: MenuComponent;

  menu = inject(SIDEBAR_MENU);
  host = inject(ElementRef, { skipSelf: true }).nativeElement;
  router = inject(Router);
  private _layoutService = inject(LayoutService);

  isOpen =
    localStorage.getItem(SIDEBAR_LOCAL_STORAGE.isOpen) === 'true' ?? true;

  select(args: MenuEventArgs) {
    args.event?.preventDefault();

    const routerLink = args.item.url;
    if (routerLink) {
      this.router.navigate([routerLink]);
    }

    const id = args.item.id;
    if (id === 'collapse-button') {
      this.toggle();
    }
  }

  private toggle() {
    this.isOpen = !this.isOpen;
    localStorage.setItem(SIDEBAR_LOCAL_STORAGE.isOpen, this.isOpen.toString());
    this.refreshMenu();
    this._layoutService.toggleSidebar(this.isOpen);
  }

  sidebarCreated() {
    setTimeout(() => {
      this.isOpen ? this.sidebarRef?.show() : this.sidebarRef?.hide();
    });
  }

  menuCreated() {
    this.refreshMenu();
  }

  refreshMenu() {
    setTimeout(() => {
      this.menuRef?.refresh();
    });
  }

  onBeforeItemRender(args: MenuEventArgs) {
    args.element.setAttribute('title', args.item.id || '');
  }
}
