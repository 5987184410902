import { Injectable, inject } from '@angular/core';
import {
  AccessToken,
  IdentityServiceBase,
  LoginModel,
  LoginResponse,
} from '@seahorse/auth';
import { DataContextService, ResultStatus } from '@seahorse/common';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private _identityService = inject(IdentityServiceBase);
  private _dataContext = inject(DataContextService);

  login(model: LoginModel) {
    return this._dataContext.post<AccessToken>(`account/login`, model).pipe(
      map((response) => {
        let loginResponse: LoginResponse;

        if (response.status === ResultStatus.OK) {
          const token = response.result;
          this._identityService.setIdentity(token.access_token);

          const isMfaEnabled = response.result?.has_mfa;
          const isMfaRequired = response.result?.required_mfa;

          loginResponse = {
            isSuccess: true,
            isUnauthorized: false,
            isMfaEnabled: isMfaEnabled,
            isMfaRequired: isMfaRequired,
          };
        } else {
          loginResponse = {
            isSuccess: false,
            isUnauthorized: true,
          };
        }

        return loginResponse;
      })
    );
  }

  verifyMfa(code: string, token?: string) {
    let url = `account/mfa/verify/${code}`;
    const params = new URLSearchParams();

    if (token) {
      params.append('token', token);
    }

    if (params.toString()) {
      url += `?${params.toString()}`;
    }

    return this._dataContext.get<boolean>(url).pipe(
      map((response) => {
        const loginResponse: LoginResponse = {
          isSuccess: response.result,
          isUnauthorized: !response.result,
        };

        return loginResponse;
      })
    );
  }
}
