import { Component, inject, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { SeahorseFormBuilder } from '@seahorse/forms';
import { languageOptions } from '../../shared/models/language.model';
import { createLoginForm } from './login.form';
import { AccountService } from '../services/account.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { IdentityServiceBase, LoginModel } from '@seahorse/auth';
import { MfaSetupCode } from '@seahorse/domain';
import { NotificationService } from '@seahorse/common';
import { MfaService, MfaSetupComponent } from '@seahorse/temp';

@Component({
  selector: 'cp-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage {
  @ViewChild('mfaSetup', { static: false })
  mfaSetupComponent?: MfaSetupComponent;

  languages = languageOptions;

  language = inject(SeahorseFormBuilder).nonNullable.control(
    'nl',
    Validators.required
  );

  verificationCode = inject(SeahorseFormBuilder).nonNullable.control(
    '',
    Validators.required
  );
  mfaSetupCode?: MfaSetupCode;

  currentForm: FormType = FormType.Login;
  formType = FormType;

  isLoading = false;
  showVerificationError = false;
  isUnauthorized = false;

  form = createLoginForm();

  private _subscriptions = new Subscription();
  private _accountService = inject(AccountService);
  private _router = inject(Router);
  private _mfaService = inject(MfaService);
  private _identityService = inject(IdentityServiceBase);
  private _notificationService = inject(NotificationService);
  private _route = inject(ActivatedRoute);

  login() {
    this.form.submit();

    if (!this.form.valid) {
      return;
    }

    const value = this.form.getRawValue();

    const model: LoginModel = {
      password: value.password as string,
      username: value.username as string,
      rememberMe: value.rememberMe,
      grantType: value.grantType as string,
    };

    this.isLoading = true;

    this._subscriptions.add(
      this._accountService.login(model).subscribe(
        (response) => {
          this.isLoading = false;
          if (response.isSuccess) {
            if (!response.isMfaEnabled && response.isMfaRequired) {
              this._mfaService
                .initMfa(this._identityService.token)
                .subscribe((res) => {
                  this.mfaSetupCode = res.result;
                  this.currentForm = FormType.SetupMFA;
                });
            } else if (response.isMfaEnabled) {
              this.currentForm = FormType.VerifyMFA;
            } else {
              const returnUrl =
                this._route.snapshot.queryParamMap.get('returnUrl');

              if (returnUrl) {
                this._router.navigateByUrl(returnUrl);
              } else {
                this._router.navigate(['/']);
              }
            }
          }
        },
        (error) => {
          this.isLoading = false;
          if (error.status === 401) {
            this.isUnauthorized = true;
          }
        }
      )
    );
  }

  sendVerificationCode(code: string) {
    if (!code) {
      this.showVerificationError = true;
      return;
    }

    this.isLoading = true;
    this._subscriptions.add(
      this._accountService
        .verifyMfa(code, this._identityService.token)
        .subscribe((response) => {
          this.isLoading = false;
          if (response.isSuccess) {
            this._router.navigate(['/']);
          } else {
            this.showVerificationError = true;
          }
        })
    );
  }

  completeMfa() {
    if (!this.mfaSetupComponent) {
      return;
    }

    const code = this.mfaSetupComponent.codeControl.getRawValue();

    if (!code.length) {
      this._notificationService.showError('user.mfa.verificationCodeRequired');

      return;
    }

    this.isLoading = true;
    this._subscriptions.add(
      this._mfaService
        .completeMfa(code, true, this._identityService.token)
        .subscribe((res) => {
          this.isLoading = false;
          if (res.result) {
            this._identityService.isMfaEnabled = true;
            this._router.navigate(['/']);
          } else {
            this.showVerificationError = true;
          }
        })
    );
  }
}

export enum FormType {
  Login,
  ForgotPassword,
  SetupMFA,
  VerifyMFA,
}
